import React from "react";

export interface LogoMarkProps extends React.ComponentPropsWithoutRef<"svg"> {}

const LogoMark = ({ className }: LogoMarkProps) => {
  return (
    <svg
      width="80"
      height="64"
      viewBox="0 0 80 64"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V8H52V16H24V18C24 19.0423 23.7342 20.0225 23.2668 20.8766L32.2422 36.154C29.4483 36.6494 27.0553 38.3049 25.5787 40.605L15.8233 24H6C2.68629 24 0 21.3137 0 18V6Z" />
      <path d="M62 0C58.6863 0 56 2.68629 56 6V18C56 21.3137 58.6863 24 62 24H74C77.3137 24 80 21.3137 80 18V6C80 2.68629 77.3137 0 74 0H62Z" />
      <path d="M28 46C28 42.6863 30.6863 40 34 40H46C49.3137 40 52 42.6863 52 46V58C52 61.3137 49.3137 64 46 64H34C30.6863 64 28 61.3137 28 58V46Z" />
    </svg>
  );
};

export default LogoMark;
