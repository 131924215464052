import { Link as GLink, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Link from "../atoms/link";
import LogoMarkType from "../brand/logo-mark-type";
import { NavigationItem } from "./header";

interface FooterProps {
  footerNavigation: NavigationItem[];
  metaNavigation: NavigationItem[];
}

const Footer = ({ footerNavigation, metaNavigation }: FooterProps) => {
  const { t } = useTranslation(["footer"]);

  return (
    <footer className="p-8 bg-secondary-100 lg:p-12 dark:bg-secondary-800">
      <div className="flex flex-col items-center max-w-xl mx-auto space-y-6 text-center">
        <GLink
          to="/"
          className="flex items-center justify-center text-2xl font-semibold text-secondary-900 dark:text-white"
        >
          <span className="sr-only">Homepage</span>
          <LogoMarkType
            className="h-7 w-[200px] text-secondary-900"
            // markClassName="text-primary-600"
          />
        </GLink>
        <p className="max-w-xl text-secondary-500 dark:text-secondary-300">
          {t("footer:copytext")}
        </p>
        <ul className="flex flex-wrap items-center justify-center space-x-6 text-secondary-900 dark:text-white">
          {footerNavigation.map((ni) => (
            <li key={ni.slug}>
              <Link variant="link" to={ni.slug} className="">
                {ni.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex flex-col items-center w-full space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
          <p className="text-sm text-secondary-500 sm:text-center dark:text-secondary-400">
            {t("footer:copyright", { year: new Date().getFullYear() })}
          </p>
          <ul className="flex space-x-6">
            {metaNavigation.map((ni) => (
              <li key={ni.slug}>
                <Link
                  variant="link"
                  size="md"
                  to={ni.slug}
                  className="h-auto p-0 text-secondary-400"
                >
                  {ni.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
