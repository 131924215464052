import React from "react";

export interface LogoMarkTypeProps
  extends React.ComponentPropsWithoutRef<"svg"> {
  markClassName?: string;
  typeClassName?: string;
}

const LogoMarkType = ({
  className,
  markClassName,
  typeClassName,
}: LogoMarkTypeProps) => {
  return (
    <svg
      width="481"
      height="64"
      viewBox="0 0 481 64"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g className={markClassName} fill="currentColor">
        <path d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V8H52V16H24V18C24 19.0423 23.7342 20.0225 23.2668 20.8766L32.2422 36.154C29.4483 36.6494 27.0553 38.3049 25.5787 40.605L15.8233 24H6C2.68629 24 0 21.3137 0 18V6Z" />
        <path d="M62 0C58.6863 0 56 2.68629 56 6V18C56 21.3137 58.6863 24 62 24H74C77.3137 24 80 21.3137 80 18V6C80 2.68629 77.3137 0 74 0H62Z" />
        <path d="M28 46C28 42.6863 30.6863 40 34 40H46C49.3137 40 52 42.6863 52 46V58C52 61.3137 49.3137 64 46 64H34C30.6863 64 28 61.3137 28 58V46Z" />
      </g>

      <g className={typeClassName} fill="currentColor">
        <path d="M97 62.0703V16.2441H106.41V22.2705H106.636C107.259 20.874 108.075 19.6709 109.085 18.6611C110.116 17.6514 111.309 16.8779 112.662 16.3408C114.037 15.8037 115.541 15.5352 117.174 15.5352C120.096 15.5352 122.609 16.2549 124.715 17.6943C126.82 19.1123 128.442 21.1641 129.581 23.8496C130.72 26.5352 131.289 29.7471 131.289 33.4854V33.5176C131.289 37.2559 130.72 40.4678 129.581 43.1533C128.464 45.8389 126.863 47.9014 124.779 49.3408C122.695 50.7803 120.203 51.5 117.303 51.5C115.67 51.5 114.155 51.2314 112.759 50.6943C111.362 50.1572 110.148 49.4053 109.117 48.4385C108.086 47.4502 107.248 46.2686 106.604 44.8936H106.41V62.0703H97ZM114.08 43.7012C115.648 43.7012 117.002 43.293 118.141 42.4766C119.279 41.6387 120.16 40.457 120.783 38.9316C121.406 37.4062 121.718 35.6016 121.718 33.5176V33.4854C121.718 31.3799 121.406 29.5645 120.783 28.0391C120.16 26.5137 119.269 25.3535 118.108 24.5586C116.97 23.7422 115.627 23.334 114.08 23.334C112.555 23.334 111.212 23.7529 110.052 24.5908C108.892 25.4072 107.989 26.5781 107.345 28.1035C106.7 29.6074 106.378 31.4121 106.378 33.5176V33.5498C106.378 35.6123 106.7 37.4062 107.345 38.9316C108.011 40.457 108.913 41.6387 110.052 42.4766C111.212 43.293 112.555 43.7012 114.08 43.7012Z" />
        <path d="M136.518 50.791V16.2441H145.767V22.1416H145.96C146.476 20.0576 147.432 18.4355 148.828 17.2754C150.225 16.1152 151.943 15.5352 153.984 15.5352C154.5 15.5352 154.994 15.5674 155.467 15.6318C155.939 15.6963 156.348 15.7822 156.691 15.8896V24.1719C156.305 24.0215 155.81 23.9033 155.209 23.8174C154.607 23.71 153.963 23.6562 153.275 23.6562C151.728 23.6562 150.407 23.957 149.311 24.5586C148.216 25.1602 147.378 26.0303 146.798 27.1689C146.218 28.3076 145.928 29.6934 145.928 31.3262V50.791H136.518Z" />
        <path d="M175.648 51.5C172.146 51.5 169.117 50.7803 166.56 49.3408C164.004 47.9014 162.027 45.8389 160.631 43.1533C159.234 40.4678 158.536 37.2559 158.536 33.5176V33.4531C158.536 29.7363 159.245 26.5459 160.663 23.8818C162.081 21.1963 164.068 19.1338 166.625 17.6943C169.181 16.2549 172.179 15.5352 175.616 15.5352C179.075 15.5352 182.083 16.2549 184.639 17.6943C187.218 19.1123 189.216 21.1641 190.634 23.8496C192.052 26.5137 192.761 29.7148 192.761 33.4531V33.5176C192.761 37.2773 192.052 40.5 190.634 43.1855C189.237 45.8711 187.261 47.9336 184.704 49.373C182.147 50.791 179.129 51.5 175.648 51.5ZM175.681 44.1201C177.206 44.1201 178.527 43.7119 179.644 42.8955C180.783 42.0576 181.653 40.8545 182.255 39.2861C182.878 37.6963 183.189 35.7734 183.189 33.5176V33.4531C183.189 31.2188 182.878 29.3174 182.255 27.749C181.632 26.1807 180.751 24.9883 179.612 24.1719C178.473 23.334 177.141 22.915 175.616 22.915C174.112 22.915 172.791 23.334 171.652 24.1719C170.535 24.9883 169.665 26.1807 169.042 27.749C168.419 29.3174 168.107 31.2188 168.107 33.4531V33.5176C168.107 35.7734 168.408 37.6963 169.01 39.2861C169.633 40.8545 170.514 42.0576 171.652 42.8955C172.791 43.7119 174.134 44.1201 175.681 44.1201Z" />
        <path d="M210.751 51.5C207.85 51.5 205.347 50.7803 203.242 49.3408C201.136 47.9014 199.514 45.8389 198.376 43.1533C197.259 40.4678 196.7 37.2559 196.7 33.5176V33.4854C196.7 29.7256 197.269 26.5137 198.408 23.8496C199.547 21.1641 201.158 19.1123 203.242 17.6943C205.347 16.2549 207.85 15.5352 210.751 15.5352C213.2 15.5352 215.348 16.1367 217.196 17.3398C219.044 18.5215 220.44 20.1543 221.386 22.2383H221.579V4.28809H230.989V50.791H221.579V44.8936H221.386C220.462 46.9561 219.076 48.5781 217.228 49.7598C215.402 50.9199 213.243 51.5 210.751 51.5ZM213.909 43.7012C215.434 43.7012 216.777 43.293 217.937 42.4766C219.097 41.6387 220 40.457 220.644 38.9316C221.289 37.4062 221.611 35.6016 221.611 33.5176V33.4854C221.611 31.4014 221.278 29.6074 220.612 28.1035C219.968 26.5781 219.065 25.4072 217.905 24.5908C216.766 23.7529 215.434 23.334 213.909 23.334C212.341 23.334 210.987 23.7422 209.848 24.5586C208.71 25.375 207.829 26.5459 207.206 28.0713C206.583 29.5752 206.271 31.3799 206.271 33.4854V33.5176C206.271 35.6016 206.572 37.4062 207.174 38.9316C207.797 40.457 208.677 41.6387 209.816 42.4766C210.976 43.293 212.341 43.7012 213.909 43.7012Z" />
        <path d="M249.366 51.5C246.831 51.5 244.672 50.9844 242.888 49.9531C241.127 48.9004 239.773 47.4072 238.828 45.4736C237.904 43.54 237.442 41.2305 237.442 38.5449V16.2441H246.852V36.5791C246.852 38.835 247.379 40.5859 248.431 41.832C249.506 43.0781 251.095 43.7012 253.201 43.7012C254.619 43.7012 255.833 43.3789 256.842 42.7344C257.874 42.0898 258.669 41.1982 259.227 40.0596C259.807 38.8994 260.097 37.5781 260.097 36.0957V16.2441H269.508V50.791H260.097V45.0869H259.904C259.023 47.085 257.702 48.6533 255.94 49.792C254.178 50.9307 251.987 51.5 249.366 51.5Z" />
        <path d="M291.752 51.5C288.25 51.5 285.22 50.7695 282.664 49.3086C280.107 47.8477 278.141 45.7744 276.766 43.0889C275.391 40.3818 274.704 37.1807 274.704 33.4854V33.4531C274.704 29.7578 275.391 26.5781 276.766 23.9141C278.163 21.2285 280.129 19.166 282.664 17.7266C285.22 16.2656 288.217 15.5352 291.655 15.5352C294.792 15.5352 297.509 16.126 299.808 17.3076C302.107 18.4678 303.901 20.0469 305.19 22.0449C306.501 24.043 307.22 26.2881 307.349 28.7803V29.0059H298.616L298.551 28.7158C298.272 27.0615 297.563 25.6865 296.424 24.5908C295.286 23.4736 293.739 22.915 291.784 22.915C290.258 22.915 288.926 23.3447 287.788 24.2041C286.671 25.042 285.8 26.2559 285.177 27.8457C284.576 29.4141 284.275 31.2939 284.275 33.4854V33.5176C284.275 35.7734 284.576 37.6963 285.177 39.2861C285.8 40.8545 286.671 42.0576 287.788 42.8955C288.926 43.7119 290.269 44.1201 291.816 44.1201C293.707 44.1201 295.221 43.5938 296.36 42.541C297.52 41.4668 298.261 40.0596 298.584 38.3193L298.616 38.0293H307.381V38.2227C307.231 40.7363 306.501 42.9922 305.19 44.9902C303.879 46.9883 302.075 48.5781 299.776 49.7598C297.499 50.9199 294.824 51.5 291.752 51.5Z" />
        <path d="M325.984 51.5C322.16 51.5 319.324 50.6943 317.476 49.083C315.628 47.4717 314.705 44.8184 314.705 41.123V23.334H309.903V16.2441H314.705V7.80078H324.211V16.2441H330.528V23.334H324.211V40.0918C324.211 41.7031 324.566 42.8311 325.275 43.4756C326.005 44.0986 327.08 44.4102 328.498 44.4102C328.927 44.4102 329.303 44.3994 329.626 44.3779C329.948 44.335 330.249 44.292 330.528 44.249V51.1455C330.012 51.2314 329.368 51.3066 328.594 51.3711C327.842 51.457 326.972 51.5 325.984 51.5Z" />
        <path d="M338.56 50.791V23.334H333.855V16.2441H338.56V13.4727C338.56 10.0996 339.43 7.60742 341.17 5.99609C342.932 4.38477 345.854 3.5791 349.936 3.5791C350.795 3.5791 351.665 3.62207 352.546 3.70801C353.427 3.77246 354.136 3.8584 354.673 3.96582V10.1855C354.351 10.1211 353.975 10.0781 353.545 10.0566C353.116 10.0352 352.686 10.0244 352.256 10.0244C350.709 10.0244 349.571 10.3467 348.84 10.9912C348.11 11.6357 347.745 12.5811 347.745 13.8271V16.2441H354.609V23.334H347.97V50.791H338.56Z" />
        <path d="M371.664 51.5C369.129 51.5 366.97 50.9844 365.187 49.9531C363.425 48.9004 362.072 47.4072 361.126 45.4736C360.203 43.54 359.741 41.2305 359.741 38.5449V16.2441H369.151V36.5791C369.151 38.835 369.677 40.5859 370.73 41.832C371.804 43.0781 373.394 43.7012 375.499 43.7012C376.917 43.7012 378.131 43.3789 379.141 42.7344C380.172 42.0898 380.967 41.1982 381.526 40.0596C382.106 38.8994 382.396 37.5781 382.396 36.0957V16.2441H391.806V50.791H382.396V45.0869H382.203C381.322 47.085 380 48.6533 378.239 49.792C376.477 50.9307 374.286 51.5 371.664 51.5Z" />
        <path d="M398.485 50.791V4.28809H407.895V50.791H398.485Z" />
        <path d="M419.472 51.3389C418.011 51.3389 416.776 50.834 415.766 49.8242C414.756 48.8145 414.251 47.5791 414.251 46.1182C414.251 44.6572 414.756 43.4219 415.766 42.4121C416.776 41.4023 418.011 40.8975 419.472 40.8975C420.933 40.8975 422.168 41.4023 423.178 42.4121C424.188 43.4219 424.693 44.6572 424.693 46.1182C424.693 47.5791 424.188 48.8145 423.178 49.8242C422.168 50.834 420.933 51.3389 419.472 51.3389Z" />
        <path d="M431.017 50.791V16.2441H440.427V50.791H431.017ZM435.722 11.7969C434.304 11.7969 433.111 11.3135 432.145 10.3467C431.178 9.37988 430.694 8.23047 430.694 6.89844C430.694 5.54492 431.178 4.39551 432.145 3.4502C433.111 2.4834 434.304 2 435.722 2C437.14 2 438.332 2.4834 439.299 3.4502C440.266 4.39551 440.749 5.54492 440.749 6.89844C440.749 8.23047 440.266 9.37988 439.299 10.3467C438.332 11.3135 437.14 11.7969 435.722 11.7969Z" />
        <path d="M462.929 51.5C459.427 51.5 456.397 50.7803 453.841 49.3408C451.284 47.9014 449.308 45.8389 447.911 43.1533C446.515 40.4678 445.816 37.2559 445.816 33.5176V33.4531C445.816 29.7363 446.525 26.5459 447.943 23.8818C449.361 21.1963 451.349 19.1338 453.905 17.6943C456.462 16.2549 459.459 15.5352 462.897 15.5352C466.355 15.5352 469.363 16.2549 471.92 17.6943C474.498 19.1123 476.496 21.1641 477.914 23.8496C479.332 26.5137 480.041 29.7148 480.041 33.4531V33.5176C480.041 37.2773 479.332 40.5 477.914 43.1855C476.518 45.8711 474.541 47.9336 471.984 49.373C469.428 50.791 466.409 51.5 462.929 51.5ZM462.961 44.1201C464.486 44.1201 465.808 43.7119 466.925 42.8955C468.064 42.0576 468.934 40.8545 469.535 39.2861C470.158 37.6963 470.47 35.7734 470.47 33.5176V33.4531C470.47 31.2188 470.158 29.3174 469.535 27.749C468.912 26.1807 468.031 24.9883 466.893 24.1719C465.754 23.334 464.422 22.915 462.897 22.915C461.393 22.915 460.071 23.334 458.933 24.1719C457.815 24.9883 456.945 26.1807 456.322 27.749C455.699 29.3174 455.388 31.2188 455.388 33.4531V33.5176C455.388 35.7734 455.689 37.6963 456.29 39.2861C456.913 40.8545 457.794 42.0576 458.933 42.8955C460.071 43.7119 461.414 44.1201 462.961 44.1201Z" />
      </g>
    </svg>
  );
};

export default LogoMarkType;
