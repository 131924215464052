import React from "react";
import { twMerge } from "tailwind-merge";

export interface AppWrapperProps
  extends React.ComponentPropsWithoutRef<"div"> {}

const AppWrapper: React.FC<AppWrapperProps> = ({ children, className }) => {
  return (
    <div className={twMerge("light antialiased", className)}>
      <div className="flex flex-col min-h-screen bg-white dark:bg-secondary-900">
        {children}
      </div>
    </div>
  );
};

export default AppWrapper;
