import { GatsbyLinkProps } from "gatsby";
import { Link as GLink } from "gatsby-plugin-react-i18next";
import React, { Ref } from "react";
import { twMerge } from "tailwind-merge";

export interface ButtonProps extends Omit<GatsbyLinkProps<{}>, "ref"> {
  variant?: "primary" | "secondary" | "ghost" | "link" | "none";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "none";
  language?: string;
}

const Link = React.forwardRef(
  (
    {
      variant = "link",
      size = "md",
      className,
      children,
      to,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLAnchorElement> & Ref<HTMLDivElement>
  ) => {
    const isExternal = React.useMemo(() => {
      return to ? to.includes("://") : false;
    }, [to]);

    const cN = React.useMemo(() => {
      return twMerge(
        "flex justify-center items-center font-medium focus:outline-none transition-colors rounded-lg",
        size === "xs" && "px-1 text-2xs h-6",
        size === "sm" && "px-2 text-xs h-8",
        size === "md" && "px-3 text-sm h-10",
        size === "lg" && "px-4 text-base h-12",
        size === "xl" && "px-6 text-base h-16",
        variant === "primary" &&
          "bg-primary-600 text-white hover:bg-primary-500 focus:ring-4 focus:ring-primary-300",
        variant === "secondary" && "bg-secondary-100 text-secondary-900",
        variant === "ghost" &&
          "bg-transparent text-primary-600 dark:text-white hover:bg-secondary-50 focus:ring-4 focus:ring-secondary-300 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800",
        variant === "link" && "text-primary-600 px-0 hover:text-primary-500",
        className
      );
    }, [className, variant, size]);

    return to ? (
      isExternal ? (
        <a ref={ref} className={cN} href={to} {...rest}>
          {children}
        </a>
      ) : (
        <GLink ref={ref} className={cN} to={to} {...rest}>
          {children}
        </GLink>
      )
    ) : (
      <div ref={ref} className={cN}>
        {children}
      </div>
    );
  }
);

export default Link;
