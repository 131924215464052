import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./footer";
import { NavigationItem } from "./header";

type FooterQueryType = {
  footerNavigation: {
    nodes: {
      title: string;
      language: string;
      navigation_items: NavigationItem[];
    }[];
  };
  metaNavigation: {
    nodes: {
      title: string;
      language: string;
      navigation_items: NavigationItem[];
    }[];
  };
};

const FooterWrapper = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    footerNavigation: { nodes: footerNavigationNodes },
    metaNavigation: { nodes: metaNavigationNodes },
  }: FooterQueryType = useStaticQuery(query);

  const localizedFooterNavigation = React.useMemo(
    () => footerNavigationNodes.find((n) => n.language === language),
    [language]
  );

  const localizedMetaNavigation = React.useMemo(
    () => metaNavigationNodes.find((n) => n.language === language),
    [language]
  );

  return localizedFooterNavigation && localizedMetaNavigation ? (
    <Footer
      footerNavigation={localizedFooterNavigation.navigation_items}
      metaNavigation={localizedMetaNavigation.navigation_items}
    />
  ) : null;
};

const query = graphql`
  query FooterQuery {
    footerNavigation: allNavigationJson(filter: { name: { eq: "footer" } }) {
      nodes {
        name
        language
        navigation_items {
          label
          slug
          active
        }
      }
    }
    metaNavigation: allNavigationJson(filter: { name: { eq: "meta" } }) {
      nodes {
        name
        language
        navigation_items {
          label
          slug
          active
        }
      }
    }
  }
`;

export default FooterWrapper;
