import React from "react";

export interface ContentWrapperProps {
  children: React.ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children }) => {
  return <div className="flex-grow">{children}</div>;
};

export default ContentWrapper;
