import { Dialog } from "@headlessui/react";
import { Link as GLink } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { HiMenu, HiX } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import Button from "../atoms/button";
import Link from "../atoms/link";
import LogoMark from "../brand/logo-mark";
import LogoMarkType from "../brand/logo-mark-type";
import LanguageSwitch from "../molecules/language-switch";

export type NavigationItem = {
  label: string;
  slug: string;
  active: boolean;
};

export interface HeaderProps extends React.ComponentPropsWithoutRef<"header"> {
  navigation: NavigationItem[];
}

const Header = ({ navigation, className }: HeaderProps) => {
  const { t } = useTranslation("common");

  let [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  return (
    <header className={twMerge("z-20", className)}>
      <nav className="px-6 py-4 bg-transparent sm:py-6 border-secondary-200 lg:px-6">
        <div className="grid items-center max-w-screen-xl grid-cols-3 mx-auto">
          <GLink
            to="/"
            className="flex items-center lg:justify-center lg:order-2 focus:outline-none"
          >
            <span className="sr-only">Homepage</span>
            <LogoMarkType
              className="hidden sm:h-6 md:h-7 sm:block text-secondary-900"
              // markClassName="text-primary-600"
            />
            <LogoMark className="block w-[30px] h-6 text-secondary-900 sm:hidden " />
          </GLink>
          <div className="flex items-center justify-end col-span-2 space-x-2 lg:order-3 lg:col-span-1">
            <Link
              to={t("common:get-in-touch.target")}
              variant="primary"
              size="md"
              className="hidden sm:flex"
            >
              {t("common:get-in-touch.label")}
            </Link>
            <Link
              to={t("common:get-in-touch.target")}
              variant="primary"
              size="sm"
              className="flex sm:hidden"
            >
              {t("common:get-in-touch.label")}
            </Link>
            <LanguageSwitch className="z-30 hidden lg:block" />
            <Button
              variant="ghost"
              className="w-10 h-10 lg:hidden"
              onClick={openMobileMenu}
            >
              <HiMenu className="w-5 h-5" aria-hidden="true" />
            </Button>

            <Dialog
              open={mobileMenuOpen}
              onClose={closeMobileMenu}
              className="relative z-50"
            >
              <div className="fixed inset-0 overflow-y-auto bg-white">
                <Dialog.Panel className="flex flex-col w-full h-full px-6 py-4 mx-auto">
                  <div className="flex items-center justify-start w-full">
                    <GLink to="/" className="flex-grow">
                      <span className="sr-only">Homepage</span>
                      <LogoMarkType className="w-auto h-6 text-secondary-900" />
                    </GLink>
                    <Button
                      variant="ghost"
                      className="flex-shrink-0 w-10 p-0"
                      onClick={closeMobileMenu}
                    >
                      <HiX className="w-5 h-5" />
                    </Button>
                  </div>
                  <ul className="flex flex-col flex-grow py-6">
                    {navigation.map((item) => (
                      <li key={item.slug}>
                        <Link
                          variant="link"
                          to={item.slug}
                          size="xl"
                          onClick={closeMobileMenu}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <LanguageSwitch flat={true} />
                </Dialog.Panel>
              </div>
            </Dialog>
          </div>
          <div className="items-center justify-between hidden w-full col-span-3 lg:flex lg:w-auto lg:order-1 lg:col-span-1">
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {navigation.map((item) => (
                <li key={item.slug}>
                  <Link to={item.slug} variant="link" aria-current="page">
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
