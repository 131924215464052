import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import Header, { NavigationItem } from "./header";

type NavBarQueryType = {
  allNavigationJson: {
    nodes: {
      id: string;
      name: string;
      language: string;
      navigation_items: NavigationItem[];
    }[];
  };
};

export type NavState = {
  pinned: boolean;
  unpinned: boolean;
  top: boolean;
  not_top: boolean;
  bottom: boolean;
  not_bottom: boolean;
};

const triggerY = -600;

const HeaderWrapper = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const [navState, setNavState] = React.useState<NavState>({
    pinned: false,
    unpinned: true,
    top: true,
    not_top: false,
    bottom: false,
    not_bottom: true,
  });

  useScrollPosition(({ currPos }) => {
    const pinned = currPos.y <= triggerY;
    const top = currPos.y === 0;
    const bottom = currPos.y === 0;

    const newNavState = {
      pinned,
      unpinned: !pinned,
      top,
      not_top: !top,
      bottom,
      not_bottom: !bottom,
    };

    setNavState(newNavState);
  }, []);

  const {
    allNavigationJson: { nodes },
  }: NavBarQueryType = useStaticQuery(query);

  const localizedNavigation = React.useMemo(
    () => nodes.find((n) => n.language === language),
    [language]
  );

  return localizedNavigation ? (
    <div className="relative h-[88px]">
      <Header
        navigation={localizedNavigation.navigation_items}
        className={twMerge(
          "fixed w-full z-50 top-0 transition-all duration-500",
          navState.unpinned && "-translate-y-full",
          navState.pinned &&
            "translate-y-0 bg-white shadow-md shadow-secondary-900/5",
          navState.top && "absolute translate-y-0"
        )}
      />
    </div>
  ) : null;
};

const query = graphql`
  query NavBarQuery {
    allNavigationJson(filter: { name: { eq: "main" } }) {
      nodes {
        name
        language
        navigation_items {
          label
          slug
          active
        }
      }
    }
  }
`;

export default HeaderWrapper;
