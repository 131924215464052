import React from "react";
import AppWrapper from "../components/foundation/app-wrapper";
import ContentWrapper from "../components/foundation/content-wrapper";
import FooterWrapper from "../components/foundation/footer-wrapper";
import HeaderWrapper from "../components/foundation/header-wrapper";
import ResponsiveSizes from "../components/foundation/responsive-sizes";
import ModalCookies from "../components/molecules/modal-cookies";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <AppWrapper>
        <HeaderWrapper />
        <ContentWrapper>{children}</ContentWrapper>
        <FooterWrapper />
      </AppWrapper>
      <ResponsiveSizes />
      <ModalCookies />
    </>
  );
};

export default Layout;
