import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Markdown from "markdown-to-jsx";
import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import Link from "../atoms/link";

const ModalCookies = () => {
  const { t, ready } = useTranslation("cookie-consent");

  const handleAccept = () => {
    Cookies.set("pful-consent-google-analytics", "true", { expires: 365 });
    Cookies.set("pful-consent-google-tagmanager", "true", { expires: 365 });
    initializeAndTrack(location);
  };

  const handleDecline = () => {
    // console.log("declined");
    Cookies.set("pful-consent", "false", { expires: 30 });
  };

  return (
    <CookieConsent
      cookieName="pful-consent"
      location="bottom"
      buttonText={t("cookie-consent:buttons.accept")}
      declineButtonText={t("cookie-consent:buttons.decline")}
      enableDeclineButton={true}
      disableStyles={true}
      containerClasses="cookiebanner--container"
      buttonWrapperClasses="cookiebanner--buttonwrapper"
      buttonClasses="cookiebanner--button--accept"
      declineButtonClasses="cookiebanner--button--decline"
      onAccept={handleAccept}
      onDecline={handleDecline}
    >
      <div className="space-y-2">
        <h2 className="text-2xl font-bold text-secondary-900 dark:text-white">
          {t("cookie-consent:headline")}
        </h2>
        <div className="flex flex-col space-y-2 prose-sm">
          <Markdown>{t("cookie-consent:copytext")}</Markdown>
        </div>
        <Link
          className="block text-sm text-primary-600 hover:text-primary-500"
          size="none"
          variant="none"
          to={t("cookie-consent:cta.target")}
        >
          {t("cookie-consent:cta.label")}
        </Link>
      </div>
    </CookieConsent>
  );
};

export default ModalCookies;
