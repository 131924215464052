import { Menu, Transition } from "@headlessui/react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";
import { HiGlobeAlt } from "react-icons/hi2";
import { twMerge } from "tailwind-merge";
import Button from "../atoms/button";

export interface LanguageSwitchProps {
  flat?: boolean;
  className?: string;
}

const LanguageSwitch = ({ flat = false, className }: LanguageSwitchProps) => {
  const { languages, originalPath, language } = useI18next();

  return flat ? (
    <div className="flex items-center justify-between">
      <HiGlobeAlt className="w-5 h-5" />
      <ul className="flex flex-row space-x-2">
        {languages.map((lng) => (
          <li role="language" key={lng}>
            <Link
              to={originalPath}
              language={lng}
              className={twMerge(
                "px-2 py-1 ring-1 ring-transparent hover:ring-secondary-200 font-medium text-sm uppercase rounded text-center transition-all w-10 h-10 flex justify-center items-center",
                lng === language && "ring-secondary-900 pointer-events-none"
              )}
            >
              {lng}
              {/* {t(`languages.${lng}`)} */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Menu
      as="div"
      className={twMerge("relative inline-block text-left", className)}
    >
      <div>
        <Menu.Button as={Button} variant="ghost" className="w-10 px-1">
          <HiGlobeAlt
            className="relative w-6 h-6 text-secondary-400 top-1"
            aria-hidden="true"
          />
          <p className="absolute top-0.5 px-1 font-bold text-white uppercase rounded -translate-x-1/2 left-1/2 text-2xs bg-primary-600">
            {language}
          </p>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-auto mt-2 origin-top-right bg-white divide-y rounded-lg shadow-lg divide-secondary-100 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ul className="flex p-4" role="group">
            {languages.map((lng) => (
              <Menu.Item key={lng}>
                {({ close }) => (
                  <li
                    className={twMerge(
                      "w-full uppercase text-sm font-medium text-primary-600 bg-transparent first:rounded-l last:rounded-r border-y border-l border-primary-600 hover:bg-primary-600/10 hover:text-primary-600 focus:z-10 focus:ring-2 focus:ring-primary-300  dark:border-primary-400 dark:text-primary-600 dark:hover:text-primary-300 dark:hover:bg-primary-300/10 last:border-r cursor-pointer",
                      lng === language &&
                        "bg-primary-600 pointer-events-none text-white dark:text-secondary-900"
                    )}
                    role="language"
                  >
                    <Link
                      to={originalPath}
                      language={lng}
                      className="block px-4 py-2 text-inherit"
                      onClick={() => {
                        setTimeout(close, 250);
                      }}
                    >
                      {lng}
                    </Link>
                  </li>
                )}
              </Menu.Item>
            ))}
          </ul>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageSwitch;
